<template>
	<div class="h-full">
		<fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange"
			@onPageChange="onPageChange" :queryParam="queryParam">
			<div slot="buttonGroup" class="h-handle-button" v-show="!config.isDetailDisplay">
				<div class="h-b"><el-button type="primary" size="small" @click="add"
						v-right-code="'Modelinfo:Create'">新增</el-button> </div>
				<div class="h-b"><el-button type="primary" size="small" @click="enable" v-right-code="'Modelinfo:Disable'">启用</el-button>
				</div>
				<div class="h-b"><el-button type="primary" size="small" @click="disable"
						v-right-code="'Modelinfo:Disable'">停用</el-button> </div>
				<div class="h-b"><el-button type="primary" size="small" @click="del" v-right-code="'Modelinfo:Delete'">删除</el-button>
				</div>
				<div class="h-b"><el-button type="primary" size="small" @click="importData"
						v-right-code="'Modelinfo:Importdata'">导入</el-button> </div>
			</div>
			<p slot="elList">
				<el-table ref="apiListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange"
					highlight-current-row>
					<el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
					<el-table-column v-for="(col, index, count) in dataSource.ColDefs.BodyFieldParams" :key="index"
						:prop="col.FieldName" :label="col.DisplayName" :render-header="bindFilter(queryParam, col)"
						:fixed="index == 0 && (!config || !config.isDetailDisplay)"
						:min-width="(!config || !config.isDetailDisplay) ? parseInt(col.Width) : 0" v-if="col.Visible">
						<template slot-scope="scope">
							<span v-if="col.FieldName === 'Code'"><span type="text" @click="rowClick(scope.row)"
									style="text-decoration:underline;color:#1874CD;cursor:pointer">{{
			scope.row[col.FieldName] }}</span></span>
							<span v-else> {{ scope.row[col.FieldName] }}</span>
						</template>
					</el-table-column>
					<!-- <el-table-column label="操作" align="center">
						<template slot-scope="scope">
							<el-button type="text" @click="editClick(scope.row)">编辑</el-button>
						</template>
					</el-table-column> -->
				</el-table>
			</p>
		</fixed-list>
		<DialogEx :options="importConfigTemplate" title="数据导入" @onOk="onImportOk">
            <ImportTemplate ref="importDataTemplate" :dataSource="importDataSource"></ImportTemplate>
        </DialogEx>
	</div>
</template>
<script>
export default {
	mounted() {
		this.Event.$on("reloadRolePageList", () => this.reloadPageList());
		this.Event.$on("onAddRole", () => this.add());
		this.reloadPageList();
	},
	data() {
		return {
			importConfigTemplate:
            {
                visible: false,
                size: 'small',
            },
			multipleSelection: [],
			dataSource: {
				ColDefs: {
					BodyFieldParams: []
				},
				Result: [],
				TotalCount: 0
			},
			queryParam: {
				PageIndex: 1,
				PageSize: 10
			},
			importDataSource: {
				businessType: 8,
				importAction: "omsapi/modelinfo/importData",
			},
		}
	},
	props: {
		config: {
			isDetailDisplay: false,
		}
	},
	methods: {
		reloadPageList() {
			this.onPageChange(this.queryParam);
		},
		onDataSourceChange(ds) {
			var _this = this;
			_this.dataSource = {
				ColDefs: {
					BodyFieldParams: []
				},
				Result: [],
				TotalCount: 0
			};
			_this.$nextTick(function () {
				_this.dataSource = ds;
			});
		},
		onPageChange(param) {
			this.queryParam = param;
			this.$ajax.send("omsapi/modelinfo/getpagelist", "post", param, data => {
				this.dataSource = data;
			});
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		onChangeEditDataSource(data) {
			this.config.isDetailDisplay = true;
			this.$emit("onChangeEditDataSource", data);
		},
		onChangeDetailDataSource(data) {
			this.config.isDetailDisplay = true;
			this.$emit("onChangeDetailDataSource", data);
		},
		add() {

			this.$ajax.send("omsapi/modelinfo/getempty", "get", {}, (data) => {
				this.onChangeEditDataSource(data.Result);
			});
			//  this.onChangeEditDataSource(null);
		},
		enable() {
			if (this.multipleSelection == null || this.multipleSelection.length == 0) {
				this.Utils.messageBox(`未选数据.`, "error");
				return false;
			}

			var filterRows = this.multipleSelection.filter(p => p.ApiStatus === 100);
			if (filterRows.length > 0) {
				this.Utils.messageBox("只能选择未启用状态的数据.", "error");
				return false;
			}
			var ids = this.Utils.selectionsToArr(this.multipleSelection, "Id");
			var _this = this
			this.$ajax.send("omsapi/modelinfo/enable", "post", ids, data => {
				if (data.IsSuccess) {
					_this.Utils.messageBox("保存成功.", "success");
					_this.reloadPageList();
				} else {
					_this.Utils.messageBox(data.OperationDesc, "error");
				}
			});
		},
		disable() {
			if (this.multipleSelection == null || this.multipleSelection.length == 0) {
				this.Utils.messageBox(`未选数据.`, "error");
				return false;
			}

			var filterRows = this.multipleSelection.filter(p => p.ApiStatus === 200);
			if (filterRows.length > 0) {
				this.Utils.messageBox("只能选择已启用状态的数据.", "error");
				return false;
			}
			var ids = this.Utils.selectionsToArr(this.multipleSelection, "Id");
			var _this = this
			this.$ajax.send("omsapi/modelinfo/disable", "post", ids, data => {
				if (data.IsSuccess) {
					_this.Utils.messageBox("保存成功.", "success");
					_this.reloadPageList();
				} else {
					_this.Utils.messageBox(data.OperationDesc, "error");
				}
			});
		},
		del() {
			if (this.multipleSelection == null || this.multipleSelection.length == 0) {
				this.Utils.messageBox(`未选数据.`, "error");
				return false;
			}
			var ids = this.Utils.selectionsToArr(this.multipleSelection, "Id");
			var _this = this
			this.$confirm('确定要删除吗？').then(_ => {
				_this.$ajax.send("omsapi/modelinfo/delete", "post", ids, data => {
					if (data.IsSuccess) {
						_this.Utils.messageBox("删除成功.", "success");
						_this.reloadPageList();
					} else {
						_this.Utils.messageBox(data.OperationDesc, "error");
					}
				});
			}).catch(_ => { });
		},
		rowClick: function (row) {
			var _this = this;
			this.$ajax.send("omsapi/modelinfo/get?id=" + row.Id, "get", {}, (data) => {
				this.onChangeEditDataSource(data.Result);
			});

		},
		onImportOk: function (options) {
            var _this = this;
            _this.$refs.importDataTemplate.saveFlie(options);
            this.onPageChange(_this.queryParam);
        },
		editClick(row) {
			this.onChangeEditDataSource(row.Id);
		},
		importData() {
			this.importConfigTemplate.visible = true;
		},
	},
	components: {
        "ImportTemplate": resolve => { require(['../../../../components/enterprise/components/importTemplate.vue'], resolve) }
    }
}
</script>